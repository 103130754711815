export const answers = [
  {
    label: 'Strongly disagree',
    value: 1,
  },
  {
    label: 'Disagree',
    value: 2,
  },
  {
    label: 'Neutral',
    value: 3,
  },
  {
    label: 'Agree',
    value: 4,
  },
  {
    label: 'Strongly agree',
    value: 5,
  },
]

export const socialLinks = [
  {
    value: 'Facebook',
  },
  {
    value: 'Instagram',
  },
  {
    value: 'Twitter',
  },
  {
    value: 'Magazine',
  },
  {
    value: 'Family',
  },
  {
    value: 'Friend',
  },
  {
    value: 'Search Engine',
  },
  {
    value: 'Psychology Today',
  },
  {
    value: 'Our Website',
  },
]

export const questions = [
  {
    label: 'Little or no interest in doing things?',
    value: 'question_1',
  },
  {
    label: 'Feeling down depressed or hopeless?',
    value: 'question_2',
  },
  {
    label: 'Trouble falling or staying asleep, or sleeping too much',
    value: 'question_3',
  },
  {
    label: 'Feeling tired or having little energy',
    value: 'question_4',
  },
  {
    label: 'Poor appetite or overeating',
    value: 'question_5',
  },

  {
    label:
      'Feeling bad about yourself–or that you are a failure or have let yourself or family down',
    value: 'question_6',
  },
  {
    label:
      'Trouble concentrating on things, such as reading the newspaper or watching television',
    value: 'question_7',
  },
  {
    label:
      'Moving or speaking so slowly that other people could have noticed? Or the opposite–being so fidgety or restless that you have been moving around a lot more than usual',
    value: 'question_8',
  },
  {
    label:
      'Thoughts that you would be better off dead or hurting yourself in some way',
    value: 'question_9',
  },
]

export const usa_regions = [
  { COUNTRY_CODE: 'US', FIPS_CODE: '1', REGION_NAME: 'Alabama' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '10', REGION_NAME: 'Delaware' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: '11',
    REGION_NAME: 'District of Columbia',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: '12', REGION_NAME: 'Florida' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '13', REGION_NAME: 'Georgia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '15', REGION_NAME: 'Hawaii' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '16', REGION_NAME: 'Idaho' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '17', REGION_NAME: 'Illinois' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '18', REGION_NAME: 'Indiana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '19', REGION_NAME: 'Iowa' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '2', REGION_NAME: 'Alaska' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '20', REGION_NAME: 'Kansas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '21', REGION_NAME: 'Kentucky' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '22', REGION_NAME: 'Louisiana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '23', REGION_NAME: 'Maine' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '24', REGION_NAME: 'Maryland' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '25', REGION_NAME: 'Massachusetts' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '26', REGION_NAME: 'Michigan' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '27', REGION_NAME: 'Minnesota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '28', REGION_NAME: 'Mississippi' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '29', REGION_NAME: 'Missouri' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '30', REGION_NAME: 'Montana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '31', REGION_NAME: 'Nebraska' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '32', REGION_NAME: 'Nevada' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '33', REGION_NAME: 'New Hampshire' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '34', REGION_NAME: 'New Jersey' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '35', REGION_NAME: 'New Mexico' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '36', REGION_NAME: 'New York' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '37', REGION_NAME: 'North Carolina' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '38', REGION_NAME: 'North Dakota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '39', REGION_NAME: 'Ohio' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '4', REGION_NAME: 'Arizona' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '40', REGION_NAME: 'Oklahoma' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '41', REGION_NAME: 'Oregon' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '42', REGION_NAME: 'Pennsylvania' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '44', REGION_NAME: 'Rhode Island' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '45', REGION_NAME: 'South Carolina' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '46', REGION_NAME: 'South Dakota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '47', REGION_NAME: 'Tennessee' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '48', REGION_NAME: 'Texas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '49', REGION_NAME: 'Utah' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '5', REGION_NAME: 'Arkansas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '50', REGION_NAME: 'Vermont' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '51', REGION_NAME: 'Virginia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '53', REGION_NAME: 'Washington' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '54', REGION_NAME: 'West Virginia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '55', REGION_NAME: 'Wisconsin' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '56', REGION_NAME: 'Wyoming' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '6', REGION_NAME: 'California' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '8', REGION_NAME: 'Colorado' },
  { COUNTRY_CODE: 'US', FIPS_CODE: '9', REGION_NAME: 'Connecticut' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'AA',
    REGION_NAME: 'Armed Forces Americas',
  },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'AE',
    REGION_NAME: 'Armed Forces Europe, Middle East, & Canada',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'AK', REGION_NAME: 'Alaska' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'AL', REGION_NAME: 'Alabama' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'AP',
    REGION_NAME: 'Armed Forces Pacific',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'AR', REGION_NAME: 'Arkansas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'AS', REGION_NAME: 'American Samoa' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'AZ', REGION_NAME: 'Arizona' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'CA', REGION_NAME: 'California' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'CO', REGION_NAME: 'Colorado' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'CT', REGION_NAME: 'Connecticut' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'DC',
    REGION_NAME: 'District of Columbia',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'DE', REGION_NAME: 'Delaware' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'FL', REGION_NAME: 'Florida' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'FM',
    REGION_NAME: 'Federated States of Micronesia',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'GA', REGION_NAME: 'Georgia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'GU', REGION_NAME: 'Guam' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'HI', REGION_NAME: 'Hawaii' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'IA', REGION_NAME: 'Iowa' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'ID', REGION_NAME: 'Idaho' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'IL', REGION_NAME: 'Illinois' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'IN', REGION_NAME: 'Indiana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'KS', REGION_NAME: 'Kansas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'KY', REGION_NAME: 'Kentucky' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'LA', REGION_NAME: 'Louisiana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MA', REGION_NAME: 'Massachusetts' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MD', REGION_NAME: 'Maryland' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'ME', REGION_NAME: 'Maine' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'MH',
    REGION_NAME: 'Marshall Islands',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MI', REGION_NAME: 'Michigan' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MN', REGION_NAME: 'Minnesota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MO', REGION_NAME: 'Missouri' },
  {
    COUNTRY_CODE: 'US',
    FIPS_CODE: 'MP',
    REGION_NAME: 'Northern Mariana Islands',
  },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MS', REGION_NAME: 'Mississippi' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'MT', REGION_NAME: 'Montana' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NC', REGION_NAME: 'North Carolina' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'ND', REGION_NAME: 'North Dakota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NE', REGION_NAME: 'Nebraska' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NH', REGION_NAME: 'New Hampshire' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NJ', REGION_NAME: 'New Jersey' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NM', REGION_NAME: 'New Mexico' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NV', REGION_NAME: 'Nevada' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'NY', REGION_NAME: 'New York' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'OH', REGION_NAME: 'Ohio' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'OK', REGION_NAME: 'Oklahoma' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'OR', REGION_NAME: 'Oregon' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'PA', REGION_NAME: 'Pennsylvania' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'PR', REGION_NAME: 'Puerto Rico' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'PW', REGION_NAME: 'Palau' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'RI', REGION_NAME: 'Rhode Island' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'SC', REGION_NAME: 'South Carolina' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'SD', REGION_NAME: 'South Dakota' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'TN', REGION_NAME: 'Tennessee' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'TX', REGION_NAME: 'Texas' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'UT', REGION_NAME: 'Utah' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'VA', REGION_NAME: 'Virginia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'VI', REGION_NAME: 'Virgin Islands' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'VT', REGION_NAME: 'Vermont' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'WA', REGION_NAME: 'Washington' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'WI', REGION_NAME: 'Wisconsin' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'WV', REGION_NAME: 'West Virginia' },
  { COUNTRY_CODE: 'US', FIPS_CODE: 'WY', REGION_NAME: 'Wyoming' },
]

export const providerTypes = [
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1331,
    SYSTEM_CODE_VALUE_VALUE: 'PHYSICIAN',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_PHYSICIAN',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Physician',
    SYSTEM_CODE_VALUE_NOTATION: 'Physician',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1332,
    SYSTEM_CODE_VALUE_VALUE: 'NON PHYSICIAN',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_NON_PHYSICIAN',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Non Physician',
    SYSTEM_CODE_VALUE_NOTATION: 'Non Physician',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1333,
    SYSTEM_CODE_VALUE_VALUE: 'SUPPLIER',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_SUPPLIER',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Supplier',
    SYSTEM_CODE_VALUE_NOTATION: 'Supplier',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1334,
    SYSTEM_CODE_VALUE_VALUE: 'GROUP PRACTICE',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_GROUP_PRACTICE',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Group Practice',
    SYSTEM_CODE_VALUE_NOTATION: 'Group Practice',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1335,
    SYSTEM_CODE_VALUE_VALUE: 'PRACTICE',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_PRACTICE',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Practice',
    SYSTEM_CODE_VALUE_NOTATION: 'Practice',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1336,
    SYSTEM_CODE_VALUE_VALUE: 'CLINICIAN',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_CLINICIAN',
    SYSTEM_CODE_VALUE_DESCRIPTION: null,
    SYSTEM_CODE_VALUE_NOTATION: 'Clinician',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1337,
    SYSTEM_CODE_VALUE_VALUE: 'NURSES',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_NURSES',
    SYSTEM_CODE_VALUE_DESCRIPTION: null,
    SYSTEM_CODE_VALUE_NOTATION: 'Nurses',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1338,
    SYSTEM_CODE_VALUE_VALUE: 'GENERAL_PRACTITIONER',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_GENERAL_PRACTITIONER',
    SYSTEM_CODE_VALUE_DESCRIPTION: null,
    SYSTEM_CODE_VALUE_NOTATION: 'General Practitioner',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1339,
    SYSTEM_CODE_VALUE_VALUE: 'SPECIALIST',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_SPECIALIST',
    SYSTEM_CODE_VALUE_DESCRIPTION: null,
    SYSTEM_CODE_VALUE_NOTATION: 'Specialist',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1340,
    SYSTEM_CODE_VALUE_VALUE: 'TRAINEE',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_TRAINEE',
    SYSTEM_CODE_VALUE_DESCRIPTION: null,
    SYSTEM_CODE_VALUE_NOTATION: 'Trainee',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1341,
    SYSTEM_CODE_VALUE_VALUE: 'THERAPIST',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_THERAPIST',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Therapist',
    SYSTEM_CODE_VALUE_NOTATION: 'Therapist',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1342,
    SYSTEM_CODE_VALUE_VALUE: 'SOCIAL WORKER',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_SOCIAL_WORKER',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Social Worker',
    SYSTEM_CODE_VALUE_NOTATION: 'Social Worker',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1343,
    SYSTEM_CODE_VALUE_VALUE: 'COUNSELLOR',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_COUNSELLOR',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Counsellor',
    SYSTEM_CODE_VALUE_NOTATION: 'Counsellor',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1344,
    SYSTEM_CODE_VALUE_VALUE: 'PSYCHOLOGIST',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_PSYCHOLOGIST',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Psychologist',
    SYSTEM_CODE_VALUE_NOTATION: 'Psychologist',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 1345,
    SYSTEM_CODE_VALUE_VALUE: 'PSYCHIATRIST',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_PSYCHIATRIST',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Psychiatrist',
    SYSTEM_CODE_VALUE_NOTATION: 'Psychiatrist',
  },
  {
    SYSTEM_CODE_ID: 129,
    SYSTEM_CODE_VALUE_ID: 2091,
    SYSTEM_CODE_VALUE_VALUE: 'PHARMACIST',
    SYSTEM_CODE_VALUE_NAME: 'PROVIDER_TYPE_PHARMACIST',
    SYSTEM_CODE_VALUE_DESCRIPTION: 'Pharmacist',
    SYSTEM_CODE_VALUE_NOTATION: 'Pharmacist',
  },
]
