import { Button } from '@mui/material'
import React from 'react'
import { SubTitle, TabsContainer, Title } from './Registration.styles'

const RegistrationTabs = ({ activeTab, setActiveTab }) => {
  return (
    <TabsContainer>
      <Title color="black" size="3rem">
        Join Euphoria Care!
      </Title>
      <SubTitle color="black">Sign up as a patient or provider</SubTitle>

      <div className="tabs-wrapper">
        <Button
          color="euphoria"
          size="large"
          disableElevation
          variant={activeTab === 'Patient' ? 'contained' : 'outlined'}
          onClick={() => {
            setActiveTab('Patient')
          }}
        >
          Patient
        </Button>
        &nbsp; &nbsp;
        <Button
          color="euphoria"
          size="large"
          variant={activeTab === 'Provider' ? 'contained' : 'outlined'}
          disableElevation
          onClick={() => {
            setActiveTab('Provider')
          }}
        >
          Provider
        </Button>
      </div>
    </TabsContainer>
  )
}

export default RegistrationTabs
