import React, { useState } from 'react'
import { Formik } from 'formik'
import { FormWrapper } from '../Form.styles'
import { validationSchema } from './validationSchema'
import Box from '@mui/material/Box'
import { useToasts } from 'react-toast-notifications'
import { Typography } from '@mui/material'
import ProviderDetails from './ProviderDetails'
import { API_PATHS, instance } from '../../../../services'

const ProviderSignup = ({ user }) => {
  const { addToast } = useToasts()

  const onSubmitRegistration = async values => {
    try {
      const response = await instance.post(
        `${API_PATHS.mainPath}/user-reg-service/euphoria-provider`,
        {
          ...values,
          email: user.email,
          auth0_ID: user.sub,
        }
      )

      if (response.status === 200) {
        addToast('Registration complete', {
          autoDismiss: true,
          appearance: 'success',
        })

        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.errors?.length < 0) {
        error.response.data.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      } else {
        addToast(error?.response?.data?.message, {
          autoDismiss: true,
          appearance: 'error',
        })
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          mobilePhone: '',
          address: '',
          address2: '',
          address2: '',
          state: '',
          country: '',
          postalCode: '',
          dateOfBirth: '',
          providerType: '',
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmitRegistration(values)
        }}
      >
        <>
          <FormWrapper>
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Typography variant="h5" component="h5" gutterBottom>
                Provider Sign Up
              </Typography>
            </Box>
            <ProviderDetails />
          </FormWrapper>
        </>
      </Formik>
    </>
  )
}

export default ProviderSignup
