import styled from 'styled-components'

export const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 990px) {
    justify-content: space-between;
  }
`
