import React from 'react'
import Signup from './Form/Patient/Signup'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Container,
  Title,
  SubTitle,
  Info,
  FormContainer,
  LoginBtn,
  TItleContainer,
  TabsContainer,
  TabPanel,
} from './Registration.styles'
import TabContext from '@mui/lab/TabContext'
import { useState } from 'react'
import FormTabs from './RegistrationTabs'
import ProviderSignup from './Form/Provider/ProviderSignup'

const Registration = ({ user }) => {
  const [activeTab, setActiveTab] = useState('Patient')
  const { logout } = useAuth0()
  return (
    <Container>
      <Info>
        <TItleContainer>
          <Title>
            Create an account with{' '}
            <span
              style={{
                color: '#25be77',
              }}
            >
              Euphoria!
            </span>
          </Title>
          <SubTitle>
            Become the best version of you! That's our promise to you! Already
            have an account? &nbsp; &nbsp;
            <span>
              <LoginBtn onClick={() => logout()}>Login here</LoginBtn>
            </span>
          </SubTitle>
        </TItleContainer>
      </Info>
      <FormContainer>
        <FormTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <TabPanel activeTab={activeTab} name={'Patient'}>
          <Signup user={user} />
        </TabPanel>
        <TabPanel activeTab={activeTab} name={'Provider'}>
          <ProviderSignup user={user} />
        </TabPanel>
      </FormContainer>
    </Container>
  )
}

export default Registration
