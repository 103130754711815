import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Auth } from './components/Auth/Auth'
import { Loading } from './components/Loading/Loading'
import ErrorBoundary from './components/ErrorBoundary'

import 'bootstrap/dist/css/bootstrap.min.css'
import MainDashBoard from './components/MainDashboard/MainDashboard'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    euphoria: {
      light: '#50cb92',
      main: '#25be77',
      dark: '#198553',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        focusVisible: false,
      },
    },
  },
})

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5000,
      },
    },
  })

  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastProvider placement="bottom-left">
          {isLoading ? (
            <Loading />
          ) : !isAuthenticated ? (
            loginWithRedirect()
          ) : (
            <QueryClientProvider client={queryClient}>
              <Auth>
                <BrowserRouter>
                  <ErrorBoundary>
                    <MainDashBoard />
                  </ErrorBoundary>
                </BrowserRouter>
              </Auth>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          )}
        </ToastProvider>
      </ThemeProvider>
    </>
  )
}

export default App
