import styled from 'styled-components'
import { createTheme } from '@mui/material/styles'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: black;
  color: white !important;
`

export const Info = styled.div`
  padding: 1rem;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  /* background-image: linear-gradient(
      to bottom,
      rgba(10, 10, 10, 0.5),
      rgba(10, 10, 10, 0.9)
    ),
    url('images/happyBackground.png'); */
  background-size: cover;
  background-position: 'center-top';
`

export const TItleContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: auto;
  text-align: center;
`

export const Title = styled.h1`
  color: white;
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`

export const SubTitle = styled.p`
  color: white;
  font-size: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

export const LoginBtn = styled.button`
  color: #fff;
  background: transparent;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid #25be77;
  text-decoration: none;

  :hover {
    background-color: #25be77;
  }
`
