import styled from 'styled-components'
import { createTheme } from '@mui/material/styles'
import { Button } from '@mui/material'

export const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: whitesmoke;
`

export const Info = styled.div`
  padding: 4rem;
  height: 20%;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  background-image: linear-gradient(
      to bottom,
      rgba(10, 10, 10, 0.5),
      rgba(10, 10, 10, 0.9)
    ),
    url('images/happyBackground.png');
  background-size: cover;
  background-position: 'center-top';

  @media (max-width: 768px) {
  }
`

export const TItleContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: auto;
`

export const Title = styled.h1`
  color: ${props => (props.color ? props.color : 'white')};
  font-size: ${props => (props.size ? props.size : '4rem')};

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`

export const SubTitle = styled.p`
  color: ${props => (props.color ? props.color : 'white')};
  font-size: 1.2rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    text-align: center;
  }
`

export const LoginBtn = styled.button`
  color: #25be77;
  background: transparent;
  border: none;
  text-decoration: underline;
`

export const FormContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: '#fff';
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TabsContainer = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 20px;
  }

  .tabs-wrapper {
    display: flex;
    flex-direction: row;
  }
`

export const TabPanel = styled.div`
  display: ${props => (props.activeTab === props.name ? 'block' : 'none')};
  width: 100%;
`
