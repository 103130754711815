import React from 'react'

const Plan = ({
  background,
  ProductName,
  Amount,
  Interval,
  IntervalCount,
  onClick,
}) => {
  return (
    <div
      style={{
        background: background ? background : '#fff',
        color: background ? '#fff' : '#000',
        borderRadius: '1rem',
        minWidth: '20rem',
        margin: '1rem',
        padding: '2rem',
      }}
    >
      <p style={{ fontSize: '1rem', fontWeight: 'bold' }}>{ProductName}</p>
      <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>${Amount}</span>
      <span style={{ fontSize: '1rem' }}>
        /
        {`${IntervalCount > 1 ? `every ${IntervalCount}` : ''} ${Interval}${
          IntervalCount > 1 ? 's' : ''
        }`}
      </span>
      <ul
        style={{
          fontSize: '1.1rem',
          listStyle: 'none',
          margin: '2rem 0',
        }}
      >
        <li>◼ Weekly therapy sessions</li>
        <li>◼ 30 Minute counseling sessions</li>
        <li>◼ Unlimited messaging</li>
        <li>◼ Weekly newsletter</li>
        <li>◼ 24/7 Contact center</li>
      </ul>
      <button
        style={{
          background: background ? '#fff' : '#000',
          color: background ? '#000' : '#fff',
          border: 'none',
          padding: '.5rem 2rem',
          borderRadius: '.5rem',
          fontSize: '1.3rem',
        }}
        onClick={onClick}
      >
        Subscribe
      </button>
    </div>
  )
}

export default Plan
