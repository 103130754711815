export const Plans = [
  // {
  //   PriceID: 'price_1LOL6iLfla5wrcq5nQ2ZkQem',
  //   ProductID: 'prod_M6YDopGdepUY0f',
  //   ProductName: 'Annual Subscription',
  //   ProductStatementDescriptor: '',
  //   ProductTaxCode: 'txcd_10000000',
  //   Description: '',
  //   Created: '2022-07-22 12:37',
  //   Amount: '2,599.99',
  //   Currency: 'usd',
  //   Interval: 'year',
  //   IntervalCount: 1,
  //   UsageType: 'licensed',
  //   AggregateUsage: '',
  //   BillingScheme: 'per_unit',
  //   TrialPeriodDays: '',
  //   TaxBehavior: 'exclusive',
  // },
  // {
  //   PriceID: 'price_1LOLANLfla5wrcq56r2WkD3Z',
  //   ProductID: 'prod_M6YGIw7xfZfxgZ',
  //   ProductName: 'Semi Annual Subscription',
  //   ProductStatementDescriptor: '',
  //   ProductTaxCode: 'txcd_10000000',
  //   Description: '',
  //   Created: '2022-07-22 12:41',
  //   Amount: '1,299.99',
  //   Currency: 'usd',
  //   Interval: 'month',
  //   IntervalCount: 6,
  //   UsageType: 'licensed',
  //   AggregateUsage: '',
  //   BillingScheme: 'per_unit',
  //   TrialPeriodDays: '',
  //   TaxBehavior: 'exclusive',
  // },
  // {
  //   PriceID: 'price_1LOL8sLfla5wrcq5nsB2DsMG',
  //   ProductID: 'prod_M6YFl7Z0f7y2VB',
  //   ProductName: 'Quarterly Subscription',
  //   ProductStatementDescriptor: '',
  //   ProductTaxCode: 'txcd_10000000',
  //   Description: '',
  //   Created: '2022-07-22 12:39',
  //   Amount: '639.99',
  //   Currency: 'usd',
  //   Interval: 'month',
  //   IntervalCount: 3,
  //   UsageType: 'licensed',
  //   AggregateUsage: '',
  //   BillingScheme: 'per_unit',
  //   TrialPeriodDays: '',
  //   TaxBehavior: 'exclusive',
  // },

  {
    PriceID: 'price_1MotP8Lfla5wrcq50sl7ffsI',
    ProductID: 'prod_M5CLN550LQxEVY',
    ProductName: 'Monthly Subscription',
    ProductStatementDescriptor: '',
    ProductTaxCode: 'txcd_10000000',
    Description: '',
    Created: '2022-07-18 21:58',
    Amount: '300.00',
    Currency: 'usd',
    Interval: 'month',
    IntervalCount: 1,
    UsageType: 'licensed',
    AggregateUsage: '',
    BillingScheme: 'per_unit',
    TrialPeriodDays: '',
    TaxBehavior: 'exclusive',
  },
]

export const testPlans = [
  // {
  //   PriceID: 'price_1LUqv1Lfla5wrcq54nTccIHC',
  //   ProductID: 'prod_MDHTuI4rbqA3cH',
  //   ProductName: 'Euphoria Test 2',
  //   ProductStatementDescriptor: '',
  //   ProductTaxCode: 'txcd_10000000',
  //   Description: '',
  //   Created: '2022-08-09 11:48',
  //   Amount: 1,
  //   Currency: 'usd',
  //   Interval: 'day',
  //   IntervalCount: 1,
  //   UsageType: 'licensed',
  //   AggregateUsage: '',
  //   BillingScheme: 'per_unit',
  //   TrialPeriodDays: '',
  //   TaxBehavior: 'exclusive',
  // },
  // {
  //   PriceID: 'price_1LOLsaLfla5wrcq5kj3RX4jj',
  //   ProductID: 'prod_M6Z01ouhED2XGt',
  //   ProductName: 'Euphoria Test',
  //   ProductStatementDescriptor: '',
  //   ProductTaxCode: 'txcd_10000000',
  //   Description: '',
  //   Created: '2022-07-22 13:26',
  //   Amount: 10,
  //   Currency: 'usd',
  //   Interval: 'month',
  //   IntervalCount: 1,
  //   UsageType: 'licensed',
  //   AggregateUsage: '',
  //   BillingScheme: 'per_unit',
  //   TrialPeriodDays: '',
  //   TaxBehavior: 'exclusive',
  // },
  {
    PriceID: 'price_1LY8MQLfla5wrcq5nmz2vKjZ',
    ProductID: 'prod_MGfhyPoLYDTPKC',
    ProductName: 'Euphoria Test 4',
    ProductStatementDescriptor: '',
    ProductTaxCode: 'txcd_10000000',
    Description: '',
    Created: '2022-08-18 13:02',
    Amount: 1,
    Currency: 'usd',
    Interval: 'month',
    IntervalCount: 1,
    UsageType: 'licensed',
    AggregateUsage: '',
    BillingScheme: 'per_unit',
    TrialPeriodDays: '',
    TaxBehavior: 'exclusive',
  },
]
