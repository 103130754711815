export const globalConfig = {
  apiMainBaseURL: 'https://euphoria-us.gazelleplatform.com/services/security',
  apiDatahubBaseURL:
    'https://euphoria-us.gazelleplatform.com/services/security/api-proxy-service',
  apiDiagnosticBaseURL:
    'https://euphoria-us.gazelleplatform.com/services/diagnostics',
  apiServicesUrl: 'https://euphoria-us.gazelleplatform.com/services/api/',
  securityServicesUrl:
    'https://euphoria-us.gazelleplatform.com/services/security/',
  sharedServicesUrl:
    'https://euphoria-us.gazelleplatform.com/services/security/',
  loginUrl: 'https://app.euphoria.care/',
  datahubHost: 'datahub.gazelleplatform.com',
  logoutUrl:
    'https://euphoria-care.us.auth0.com/v2/logout?client_id=OurBHJ5IJu1GZkPpc0tfp8CnjEIZfAnP&returnTo=https://www.origohealth.net/login',
  authUri:
    'https://euphoria-care.us.auth0.com/authorize?response_type=code&client_id=nSXQIFBzQGGUZlWTDeNK7gJ65MnNRlmb&redirect_uri=https://euphoria-us.gazelleplatform.com/callback&scope=openid%20profile%20email&state=S75G5ASAR9SSSS8DSFSSDS53S63S7D5FHS8G89G80YH9RTJUY5GSKGAS2L42UTF5Y36F93F7462U',
  targetUrl: 'https://euphoria-us.gazelleplatform.com/',
  userinfoUrl: 'https://euphoria-care.us.auth0.com/userinfo',
  state:
    'S75G5ASAR9SSSS8DSFSSDS53S63S7D5FHS8G89G80YH9RTJUY5GSKGAS2L42UTF5Y36F93F7462U',
  platformWelcomeMessage: 'Welcome to the digital convergence experience',
  ehxClaimsTopicName: 'gazelle.ehx.claims.queue',
  ehxClaimsServer: 'amqp://192.168.192.6',
  bearerTokenValue:
    'B234A23N4S235A52SD35B34S6Z5K7L56FA87D69L79FK879A6S57F436K2JB42K34JS234FB2S3A423H4JF34B6K45A6S45F7B5K67A5S8J8D68F5H65S76A58F',
  userInfoUrl: 'https://euphoria-care.us.auth0.com/userinfo',
  developmentUrl: 'http://localhost:3000',
  paginationLimit: 5,
  googleMapsApi: 'AIzaSyCeNLUmMgY8Nw1aYy9jjyswHwrcK5p_dOg',
  auth0: {
    domain: 'euphoria-care.us.auth0.com',
    clientId: 'OurBHJ5IJu1GZkPpc0tfp8CnjEIZfAnP',
  },
  OPENTOK_URL: 'https://tokbox.com/embed/embed/ot-embed.js?',
  MAP_BOX_ACCESS_TOKEN:
    'pk.eyJ1Ijoib3JpZ29zb2Z0d2FyZSIsImEiOiJja2lkZXAyOTUwa2hpMnhwZXphcDEwYnBlIn0.bL_Xsmn35OU6bbpI9o84vQ',
  defaultFacilityId: 3597,
  defaultProviderId: 1,
  nabla_organization_url: 'https://origo-health.pro.nabla.com',
}
