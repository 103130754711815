import axios from 'axios'

export const instance = axios.create({
  baseURL: 'https://euphoria-us.gazelleplatform.com/services',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const setToken = token => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const API_PATHS = {
  mainPath: `/security`,
  datahubPath: `/security/api-proxy-service`,
  diagnosticPath: `/diagnostics`,
}
