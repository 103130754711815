import React from 'react'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { globalConfig } from '../../config'
import Plan from './Plan'
import { Plans, testPlans } from './Plans-data'
import {
  Container,
  Info,
  TItleContainer,
  Title,
  SubTitle,
  LoginBtn,
} from './Pricing.styles'

const Pricing = ({ user, logout }) => {
  //
  const { addToast } = useToasts()

  //
  const activateSubscription = async priceID => {
    try {
      const response = await axios.post(
        `${globalConfig.apiMainBaseURL}/subscriptions/euphoria_subscribe`,
        {
          priceId: priceID,
          customerId: user?.stripe?.id,
        }
      )

      if (response?.status === 200) {
        if (response?.data?.mesage) {
          window.location.replace(response?.data?.mesage)
        }
      }
    } catch (error) {
      console.log({ error })

      if (error.response.status === 401) {
        addToast('Something went wrong, please try again', {
          autoDismiss: true,
          appearance: 'error',
        })
      } else {
        console.log(error.response.data.errors)
        error.response.data.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      }
    }
  }

  const loadSubscriptionsManagementPortal = async () => {
    try {
      const response = await axios.post(
        `${globalConfig.apiMainBaseURL}/subscriptions/euphoria_stripe_customer_portal`,
        {
          returnUrl: 'https://app.euphoria.care/',
          customerId: user?.stripe?.id,
        }
      )

      if (response?.status === 200) {
        if (response?.data?.mesage) {
          window.location.replace(response?.data?.mesage)
        }
      }
    } catch (error) {
      console.log({ error })

      if (error?.response?.status === 401) {
        addToast('Something went wrong, please try again', {
          autoDismiss: true,
          appearance: 'error',
        })
      } else {
        console.log(error?.response?.data?.errors)
        error?.response?.data?.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      }
    }
  }

  return (
    <Container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          padding: '30px 50px ',
        }}
      >
        <span>
          <LoginBtn onClick={() => loadSubscriptionsManagementPortal()}>
            ACCOUNT
          </LoginBtn>
        </span>
        &nbsp; &nbsp;
        <span>
          <LoginBtn onClick={() => logout()}>LOGOUT</LoginBtn>
        </span>
      </div>
      <Info>
        <TItleContainer>
          <Title>
            Subscribe to{' '}
            <span
              style={{
                color: '#25be77',
              }}
            >
              Euphoria!
            </span>
          </Title>
          <SubTitle>Choose the best plan for you! &nbsp; &nbsp;</SubTitle>
        </TItleContainer>
      </Info>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {Plans.map((plan, ix) => {
          return (
            <Plan
              background={(ix + 1) % 2 === 0 ? 'darkgreen' : null}
              key={plan.PriceID}
              Amount={plan.Amount}
              ProductName={plan.ProductName}
              Interval={plan.Interval}
              IntervalCount={plan.IntervalCount}
              onClick={() => activateSubscription(plan.PriceID)}
            />
          )
        })}
        {/* {process.env.NODE_ENV === 'development' ? (
          <>
            {testPlans.map((plan, ix) => {
              return (
                <Plan
                  background={(ix + 1) % 2 === 0 ? 'darkgreen' : null}
                  key={plan.PriceID}
                  Amount={plan.Amount}
                  ProductName={plan.ProductName}
                  Interval={plan.Interval}
                  IntervalCount={plan.IntervalCount}
                  onClick={() => activateSubscription(plan.PriceID)}
                />
              )
            })}
          </>
        ) : null} */}

        {/* {testPlans.map((plan, ix) => {
          return (
            <Plan
              background={(ix + 1) % 2 === 0 ? 'darkgreen' : null}
              key={plan.PriceID}
              Amount={plan.Amount}
              ProductName={plan.ProductName}
              Interval={plan.Interval}
              IntervalCount={plan.IntervalCount}
              onClick={() => activateSubscription(plan.PriceID)}
            />
          )
        })} */}
      </div>
    </Container>
  )
}

export default Pricing
