import React, { lazy, Suspense, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import * as ROUTES from './constants'
import { AuthContext } from '../Auth/Auth'
import { Loading } from '../Loading/Loading'

const Home = lazy(() => import('../DashBoards/DashBoardComponents/Home'))
const HomeMap = lazy(() => import('../DashBoards/DashBoardComponents/HomeMap'))
const Appointments = lazy(() =>
  import('../DashBoards/DashBoardComponents/Appointments')
)
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const AppointmentsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/AppointmentsCalendar')
)
const ProviderAppointmentsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/ProviderAppointmentsCalendar')
)
const VisitsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/VisitsCalendar')
)
const Labs = lazy(() => import('../DashBoards/DashBoardComponents/Labs'))
const Patients = lazy(() =>
  import('../DashBoards/DashBoardComponents/Patients')
)
const Payments = lazy(() =>
  import('../DashBoards/DashBoardComponents/Payments')
)
const Prescriptions = lazy(() =>
  import('../DashBoards/DashBoardComponents/Prescriptions')
)
const Providers = lazy(() =>
  import('../DashBoards/DashBoardComponents/Providers')
)
const Manage_prescriptions = lazy(() =>
  import('../DashBoards/DashBoardComponents/PrescribePage')
)
const Pharmacists = lazy(() =>
  import('../DashBoards/DashBoardComponents/Pharmacists')
)
const CareTeams = lazy(() =>
  import('../DashBoards/DashBoardComponents/CareTeam')
)
const CreateCareTeams = lazy(() =>
  import('../DashBoards/DashBoardForms/CreateCareTeam')
)
const ManageCareTeam = lazy(() =>
  import('../DashBoards/DashBoardForms/ManageCareTeam')
)
const Referrals = lazy(() =>
  import('../DashBoards/DashBoardComponents/Referrals')
)
const HealthCareWorkers = lazy(() =>
  import('../DashBoards/DashBoardComponents/HealthCare')
)

const Visits = lazy(() => import('../DashBoards/DashBoardComponents/Visits'))
const Documents = lazy(() => import('../pages/UserProfile/Documents'))
const Subscribers = lazy(() =>
  import('../DashBoards/DashBoardComponents/Subscribers')
)

const CreateEhx = lazy(() => import('../DashBoards/DashBoardForms/CreateEhx'))
const CreateEhxPoc = lazy(() =>
  import('../DashBoards/DashBoardForms/CreateEhxPoc')
)
const EhxLabForm = lazy(() => import('../DashBoards/DashBoardForms/EhxLabForm'))
const EhxPatientForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPatientForm')
)
const EhxPaymentForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPaymentForm')
)
const EhxPrescriptionForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPrescriptionForm')
)
const EhxProviderForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxProviderForm/EhxProviderForm')
)
const EhxProviderScheduleForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxProviderScheduleForm')
)
const EhxUserProfileForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxUserProfileForm')
)
const DashboardContent = lazy(() =>
  import('../DashBoards/DashboardContent/DashboardContent')
)
const EhxChatForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxChatForm')
)
const ProviderSettings = lazy(() =>
  import('../pages/UserProfile/ProviderSettings')
)
const SubscriberSettings = lazy(() =>
  import('../pages/UserProfile/SubscriberSettings')
)
const Notifications = lazy(() => import('../pages/UserProfile/Notifications'))
const Accounts = lazy(() => import('../pages/UserProfile/Accounts'))
const UserProfile = lazy(() => import('../pages/UserProfile/UserProfile'))
const HealthFacilities = lazy(() =>
  import('../DashBoards/DashBoardComponents/HealthFacilities')
)
const EhxHealthCareFacilityForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxHealthCareFacilityForm')
)

const EhxAppointmentFormPatientNew = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Patient/New')
)
const EhxAppointmentFormPatientEdit = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Patient/Edit')
)
const EhxAppointmentFormProviderNew = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Provider/New')
)
const EhxAppointmentFormProviderEdit = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Provider/Edit')
)

const EhxSubscriberForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxSubscriberForm')
)
const EhxVisitFormPatient = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxVisitFormPatient')
)
const EhxVisitFormProvider = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxVisitFormProvider')
)
const Subscriptions = lazy(() =>
  import('../DashBoards/DashBoardComponents/Subscriptions')
)

const AdminAllUsers = lazy(() => import('../pages/Admin/Users'))
const AdminSubscriberDetails = lazy(() =>
  import('../pages/Admin/SubscriberDetails')
)

const AdminNewSubscriber = lazy(() =>
  import('../pages/Admin/adminForms/OnboardSubscriberForm')
)

const RouterConfig = ({ sessionObject, membershipType }) => {
  const userMetadata = useContext(AuthContext)

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={ROUTES.SUBSCRIBERS}>
          <Subscribers />
        </Route>
        <Route path={ROUTES.SUBSCRIPTIONS}>
          <Subscriptions />
        </Route>
        <Route path={ROUTES.VISITS_CALENDAR}>
          <VisitsCalendar />
        </Route>
        <Route path={ROUTES.PRESCRIPTIONS}>
          <Prescriptions />
        </Route>

        <Route path={ROUTES.LABS}>
          <Labs />
        </Route>
        <Route path={ROUTES.PAYMENTS}>
          <Payments />
        </Route>
        <Route path={ROUTES.CREATE_PROVIDER}>
          <EhxProviderForm />
        </Route>
        <Route path={ROUTES.CREATE_PATIENT}>
          <EhxPatientForm />
        </Route>

        {/**
         *  APPOINTMENTS RELATED ROUTES
         */}
        <Route path={ROUTES.CREATE_APPOINTMENT}>
          {parseInt(userMetadata.membershipType) !== 3000 ? (
            <EhxAppointmentFormProviderNew />
          ) : (
            <EhxAppointmentFormPatientNew />
          )}
        </Route>
        <Route path={ROUTES.UPDATE_APPOINTMENT}>
          {parseInt(userMetadata.membershipType) !== 3000 ? (
            <EhxAppointmentFormProviderEdit />
          ) : (
            <EhxAppointmentFormPatientEdit />
          )}
        </Route>
        <Route path={ROUTES.APPOINTMENTS_CALENDAR}>
          <AppointmentsCalendar />
        </Route>
        <Route path={ROUTES.PROVIDER_APPOINTMENTS_CALENDAR}>
          <ProviderAppointmentsCalendar />
        </Route>
        <Route path={ROUTES.APPOINTMENTS}>
          <Appointments />
        </Route>

        <Route path={ROUTES.CREATE_PRESCRIPTION}>
          <EhxPrescriptionForm />
        </Route>
        <Route path={ROUTES.CREATE_LAB}>
          <EhxLabForm />
        </Route>
        <Route path={ROUTES.CREATE_PAYMENT}>
          <EhxPaymentForm />
        </Route>
        <Route path={ROUTES.UPDATE_PATIENT}>
          <EhxPatientForm />
        </Route>
        <PrivateRoute path={ROUTES.PATIENTS}>
          <Patients />
        </PrivateRoute>
        <Route path={ROUTES.UPDATE_VISIT}>
          {parseInt(userMetadata.membershipType) !== 3000 ? (
            <EhxVisitFormProvider />
          ) : (
            <EhxVisitFormPatient />
          )}
        </Route>
        <Route path={ROUTES.PHARMACISTS}>
          <Pharmacists />
        </Route>
        <Route path={ROUTES.CARE_TEAMS}>
          <CareTeams />
        </Route>
        <Route path={ROUTES.CREATE_CARE_TEAMS}>
          <CreateCareTeams />
        </Route>
        <Route path={ROUTES.MANAGE_CARE_TEAMS}>
          <ManageCareTeam />
        </Route>
        <Route path={ROUTES.REFERRAL}>
          <Referrals />
        </Route>
        <Route path={ROUTES.MANAGE_PRESCRIPTIONS}>
          <Manage_prescriptions />
        </Route>
        <Route path={ROUTES.HEALTHCARE_WORKERS}>
          <HealthCareWorkers />
        </Route>
        <Route path={ROUTES.VISITS}>
          <Visits />
        </Route>
        <Route path={ROUTES.MANAGE_SUBSCRIBER}>
          <EhxSubscriberForm />
        </Route>

        <Route path={ROUTES.MANAGE_LAB}>
          <EhxLabForm />
        </Route>
        <Route path={ROUTES.MANAGE_PAYMENT}>
          <EhxPaymentForm />
        </Route>

        {/* Healthcare facilites */}
        <Route path={ROUTES.UPDATE_HEALTHCARE_FACILITY}>
          <EhxHealthCareFacilityForm />
        </Route>
        <Route path={ROUTES.HEALTHCARE_FACILITY}>
          <HealthFacilities />
        </Route>

        <Route path={ROUTES.CHAT}>
          <EhxChatForm />
        </Route>

        {/* User Profile */}
        <Route
          path={ROUTES.USER_PROFILE}
          render={props => (
            <UserProfile {...props} sessionObject={sessionObject} />
          )}
        />

        {/* Providers */}
        <Route path={ROUTES.UPDATE_PROVIDER}>
          <EhxProviderForm />
        </Route>
        <Route path={ROUTES.PROVIDERS}>
          <Providers />
        </Route>

        <Route path={ROUTES.DOCUMENTS}>
          <Documents sessionObject={sessionObject} />
        </Route>

        <Route path={ROUTES.PROVIDER_SETTINGS}>
          <ProviderSettings />
        </Route>

        {/*  Admin Routes  */}
        <Route exact path={ROUTES.ADMIN_NEW_SUBSCRIBER}>
          <AdminNewSubscriber />
        </Route>
        <Route path={ROUTES.ADMIN_SUBSCRIBER_DETAILS}>
          <AdminSubscriberDetails />
        </Route>
        <Route exact path={ROUTES.ADMIN_ALL_SUBSCRIBERS}>
          <AdminAllUsers />
        </Route>
        <Route path={ROUTES.NOTIFICATIONS}>
          <Notifications />
        </Route>
        <Route path={ROUTES.ACCOUNTS}>
          <Accounts />
        </Route>

        <Route path={ROUTES.MAP}>
          <HomeMap />
        </Route>

        <Route
          path={ROUTES.HOME}
          render={props => <Home {...props} membershipType={membershipType} />}
        />
        <Route path="/" exact>
          <Redirect to={ROUTES.HOME} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default RouterConfig
